'use client'

import React from "react";
import {
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  Box,
  Heading,
} from "@chakra-ui/react";

export default function ResumeSkeleton() {
  return (
    <Box padding="6" boxShadow="lg" bg="white" maxWidth="1200px" margin="0 auto">
      {/* Personal Info Skeleton */}
      <Box marginBottom={6}>
        <SkeletonCircle size="16" marginBottom={4} />
        <Skeleton height="24px" width="50%" marginBottom={2} />
        <SkeletonText mt="4" noOfLines={2} spacing="4" skeletonHeight="20px" />
      </Box>

      {/* Section Titles Skeleton */}
      <Skeleton height="32px" width="200px" marginBottom={4} />
      {/* Skills Section Skeleton */}
      <Box display="flex" gap={4} flexWrap="wrap" marginBottom={6}>
        {Array.from({ length: 6 }).map((_, index) => (
          <Skeleton key={index} height="24px" width="100px" />
        ))}
      </Box>

      {/* Projects Section Skeleton */}
      <Skeleton height="32px" width="200px" marginBottom={4} />
      {Array.from({ length: 2 }).map((_, index) => (
        <Box key={index} marginBottom={4}>
          <Skeleton height="24px" width="60%" marginBottom={2} />
          <SkeletonText mt="4" noOfLines={3} spacing="4" skeletonHeight="16px" />
        </Box>
      ))}

      {/* Education Section Skeleton */}
      <Skeleton height="32px" width="200px" marginBottom={4} />
      {Array.from({ length: 2 }).map((_, index) => (
        <Box key={index} marginBottom={4}>
          <Skeleton height="24px" width="60%" marginBottom={2} />
          <SkeletonText mt="4" noOfLines={2} spacing="4" skeletonHeight="16px" />
        </Box>
      ))}

      {/* Work Experience Section Skeleton */}
      <Skeleton height="32px" width="200px" marginBottom={4} />
      {Array.from({ length: 2 }).map((_, index) => (
        <Box key={index} marginBottom={4}>
          <Skeleton height="24px" width="60%" marginBottom={2} />
          <SkeletonText mt="4" noOfLines={3} spacing="4" skeletonHeight="16px" />
        </Box>
      ))}

      {/* Awards Section Skeleton */}
      <Skeleton height="32px" width="200px" marginBottom={4} />
      <Box>
        {Array.from({ length: 3 }).map((_, index) => (
          <Skeleton key={index} height="16px" width="70%" marginBottom={2} />
        ))}
      </Box>
    </Box>
  );
}
