import React, { useContext } from "react";
import {
  Box,
  Grid,
  GridItem,
  Heading,
  Text,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { useMediaQuery } from "react-responsive";
import { BsEye } from "react-icons/bs";
import ResumeContext from "../../Context/ResumeContext";
import "./theme3.css";

const Theme3 = (props) => {
  const { componentRef, themeData } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const { name, address, phone, email, profile, summary, skill } =
    themeData.personalData;

  const { checkProj, checkWork, checkAward } = useContext(ResumeContext);

  const projectData = themeData.projectData || [];
  const educationData = themeData.educationData || [];
  const workData = themeData.workData || [];
  const awards = themeData.awardData?.awards || "";

  const ResumeContent = () => (
    <Box id="section-to-print" ref={componentRef}>
      <Box id="theme3" paddingBlock={10} paddingInline={20}>
        <header
          id="info"
          className={`text-center d-flex ${
            isMobile
              ? "flex-column align-items-center"
              : "justify-content-between align-items-center"
          }`}
        >
          <Box className="info-text text-start">
            <Heading
              as="h2"
              size="2xl"
              color="red.800"
              className="mb-2"
              fontFamily="serif"
            >
              {name}
            </Heading>
            <Text
              fontWeight="600"
              fontSize="md"
              className="mt-1 mb-2"
              fontFamily="serif"
            >
              {profile}
            </Text>
            <Box>
              <Box className="mt-3">
                <Text width={"190px"} fontFamily="serif" fontSize={"sm"}>
                  {address}
                </Text>
                <Text fontSize={"sm"} fontFamily="serif">
                  {phone}
                </Text>
                <Text fontSize={"sm"} fontFamily="serif">
                  {email}
                </Text>
              </Box>
            </Box>
          </Box>
        </header>
        <div className="w-100 border m-auto"></div>
        <section className="bottom-part d-flex mt-3">
          <section className="sections">
            <Box display={"flex"} className="w-full my-4">
              <Heading as="h3" size="md" minWidth={175} fontFamily="serif">
                Summary
              </Heading>
              <Box marginLeft={25}>
                <Text fontSize="sm" className="summary-text">
                  {summary}
                </Text>
              </Box>
            </Box>
            {!checkWork && (
              <>
                <div className="w-100 border m-auto"></div>
                <Box display={"flex"} className="w-full my-4">
                  <Heading as="h3" size="md" minWidth={175} fontFamily="serif">
                    Experience
                  </Heading>
                  <Box marginLeft={25} width="100%">
                    <Text fontSize="sm" className="summary-text">
                      {workData.map((work, index) => (
                        <Box key={index} className="mt-1">
                          <Heading
                            fontSize="md"
                            fontFamily="serif"
                            className="my-2"
                          >
                            {work.wTitle}
                          </Heading>
                          {work.wDescription.split(",").map((desc, i) => (
                            <p key={i}>{desc}</p>
                          ))}
                        </Box>
                      ))}
                    </Text>
                  </Box>
                </Box>
              </>
            )}

            <div className="w-100 border m-auto"></div>

            <Box display={"flex"} className="w-full my-4">
              <Heading as="h3" size="md" minWidth={175} fontFamily="serif">
                Education
              </Heading>
              <Box marginLeft={25} width="100%">
                <Text fontSize="sm">
                  {educationData.map((education, index) => (
                    <Box key={index} className="mb-4">
                      <Heading
                        fontSize="md"
                        fontFamily="serif"
                        className="mb-2"
                      >
                        {education.eTitle}
                      </Heading>
                      {education.eDescription.split(",").map((desc, i) => (
                        <p key={i}>{desc}</p>
                      ))}
                    </Box>
                  ))}
                </Text>
              </Box>
            </Box>

            {!checkProj && (
              <>
                <div className="w-100 border m-auto"></div>
                <Box display={"flex"} className="w-full my-4">
                  <Heading as="h3" size="md" minWidth={175} fontFamily="serif">
                    Projects
                  </Heading>
                  <Box marginLeft={25} width="100%">
                    <Text fontSize="sm">
                      {projectData.map((project, index) => (
                        <Box key={index} className="mt-1">
                          <Heading
                            fontSize="md"
                            fontFamily="serif"
                            className="my-2"
                          >
                            {project.pTitle}
                          </Heading>
                          <Box className="sub-details">
                            {project.pDescription.split(",").map((desc, i) => (
                              <li key={i}>{desc}</li>
                            ))}
                          </Box>
                        </Box>
                      ))}
                    </Text>
                  </Box>
                </Box>
              </>
            )}

            <div className="w-100 border m-auto"></div>

            <Box display={"flex"} className="w-full my-4">
              <Heading as="h3" size="md" minWidth={175} fontFamily="serif">
                Skills
              </Heading>
              <Box marginLeft={25} width="100%">
                <Grid templateColumns="repeat(2, 1fr)" gap={5}>
                  {skill.split(",").map((item, index) => (
                    <GridItem key={index}>
                      <Box display="flex" alignItems="center">
                        <div
                          style={{
                            background: "black",
                            width: "6px",
                            height: "6px",
                          }}
                        ></div>
                        <p
                          className="mx-1"
                          color="gray"
                          fontFamily="serif"
                          key={index}
                        >
                          {item}
                        </p>
                      </Box>
                    </GridItem>
                  ))}
                </Grid>
              </Box>
            </Box>
            {!checkAward && (
              <>
                <div className="w-100 border m-auto"></div>

                <Box display={"flex"} className="w-full my-4">
                  <Heading as="h3" size="md" minWidth={175} fontFamily="serif">
                    Achievement
                  </Heading>
                  <Box marginLeft={25} width="100%">
                    <Grid templateColumns="repeat(2, 1fr)" gap={5}>
                      {awards.split(",").map((item, index) => (
                        <GridItem key={index}>
                          <Box display="flex" alignItems="center">
                            <div
                              style={{
                                background: "black",
                                width: "6px",
                                height: "6px",
                              }}
                            ></div>
                            <p
                              className="mx-1"
                              color="gray"
                              fontFamily="serif"
                              key={index}
                            >
                              {item}
                            </p>
                          </Box>
                        </GridItem>
                      ))}
                    </Grid>
                  </Box>
                </Box>
              </>
            )}
          </section>
        </section>
      </Box>
    </Box>
  );

  return (
    <>
      {isMobile ? (
        <>
          <Box position="fixed" bottom={4} right={4} zIndex={10}>
            <IconButton
              aria-label="Show Resume Preview"
              icon={<BsEye />}
              onClick={onOpen}
              colorScheme="blue"
              rounded="full"
              size="lg"
            />
          </Box>
          <Modal isOpen={isOpen} onClose={onClose} size="full">
            <ModalOverlay />
            <ModalContent maxWidth="100%" height="100vh" overflowY="auto" padding="0">
              <ModalCloseButton />
              <ModalBody>
                <ResumeContent />
              </ModalBody>
            </ModalContent>
          </Modal>
        </>
      ) : (
        <ResumeContent />
      )}
    </>
  );
};

export default Theme3;
