import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
 
} from "@chakra-ui/react";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { FaPrint, FaSave } from "react-icons/fa";
import UserDataCollect from "../Components/UserDataCollect/UserDataCollect";
import ResumeContext from "../Context/ResumeContext";

import { toast } from "sonner";
import "./BuilderArea.css";
import ResumeSkeleton from "./skeleton/ResumeSkeleton";

const BuilderArea = ({ theme, token }) => {
  const {
    setCurrentTheme,
    handlePrint,
    currentTheme,
    themeData,
    setThemeData,
  } = useContext(ResumeContext);
  const [show, setShow] = useState(false);
  const [resumeName, setResumeName] = useState("");
  const [resumes, setResumes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [resumeId, setResumeId] = useState("");
  useEffect(() => {
    const fetchResumes = async () => {
      if (!token) return;
      try {
        setLoading(true);
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/resume/user/all?token=${token}`
        );
        setResumes(res.data.data);
      } catch (e) {
        toast.error(e?.response?.data?.message ?? e.message);
      } finally {
        setLoading(false);
      }
    };
    fetchResumes();
  }, [token]);
  const handleSave = async () => {
    try {
      if (!resumeName) {
        toast.error("Please enter a resume name");
        return;
      }
      if (!token) {
        toast.error("Resume token is not found");
        return;
      }
      if (!themeData) {
        toast.error("Resume data is not found");
        return;
      }

      const { personalData, projectData, educationData, workData, awardData } =
        themeData;

      // Validate personal data
      if (!personalData.name) {
        toast.error("Please enter your name");
        return;
      }
      if (!personalData.summary) {
        toast.error("Please enter a summary");
        return;
      }
      if (!personalData.profile) {
        toast.error("Please enter your work profile");
        return;
      }
      if (!personalData.address) {
        toast.error("Please enter your address");
        return;
      }
      if (!personalData.phone) {
        toast.error("Please enter your phone number");
        return;
      }
      if (!personalData.email) {
        toast.error("Please enter your email address");
        return;
      }
      if (!personalData.skill) {
        toast.error("Please enter your skills");
        return;
      }

      // Validate project data
      if (projectData.length > 0) {
        for (let i = 0; i < projectData.length; i++) {
          if (!projectData[i].pTitle || !projectData[i].pDescription) {
            toast.error(
              `Please fill in the project title and description for project ${
                i + 1
              }`
            );
            return;
          }
        }
      }

      // Validate education data
      if (educationData.length > 0) {
        for (let i = 0; i < educationData.length; i++) {
          if (!educationData[i].eTitle || !educationData[i].eDescription) {
            toast.error(
              `Please fill in the education title and description for education ${
                i + 1
              }`
            );
            return;
          }
        }
      }

      // Validate work data
      if (workData.length > 0) {
        for (let i = 0; i < workData.length; i++) {
          if (!workData[i].wTitle || !workData[i].wDescription) {
            toast.error(
              `Please fill in the work title and description for work ${i + 1}`
            );
            return;
          }
        }
      }

      // Validate award data
      if (awardData.awards.length === 0) {
        toast.error("Please enter your awards");
        return;
      }

      setLoading(true);
      await axios.post(`${process.env.REACT_APP_API_URL}/api/resume`, {
        resumeName,
        token,
        ...themeData,
      });

      // If all checks pass, proceed with saving
      setShow(false);
      toast.success("Resume saved successfully");
    } catch (e) {
      toast.error(e?.response?.data?.message ?? e.message);
    } finally {
      setLoading(false);
    }
  };
  const handleResumeChange = (e) => {
    const selectedResumeId = e.target.value;
    if (selectedResumeId !== resumeId) {
      setResumeId(selectedResumeId);
    }

    // Predefined resume data for different professions
    if (selectedResumeId === "resume1") {
      const teachingResume = {
        personalData: {
          name: "Jane Smith",
          summary:
            "A passionate and dedicated educator with 10+ years of experience in primary education.",
          profile: "Primary School Teacher",
          address: "45 Learning St, Education City",
          phone: "+9876543210",
          email: "jane.smith@teacher.com",
          skill:
            "Classroom Management, Lesson Planning, Child Development, Communication",
        },
        projectData: [
          {
            pTitle: "Interactive Learning Program",
            pDescription:
              "Developed an interactive curriculum that improved student engagement by 40%.",
          },
        ],
        educationData: [
          {
            eTitle: "Master of Education",
            eDescription: "Graduated with Distinction from ABC University.",
          },
        ],
        workData: [
          {
            wTitle: "Lead Teacher at XYZ School",
            wDescription:
              "Led a team of teachers and coordinated the curriculum for grades 1-5.",
          },
        ],
        awardData: {
          awards: `Teacher of the Year 2022 Innovation in Education Award 2021`,
        },
      };
      setThemeData(teachingResume);
    } else if (selectedResumeId === "resume2") {
      const engineerResume = {
        personalData: {
          name: "John Doe",
          summary:
            "A results-driven software engineer with 5+ years of experience in full-stack development.",
          profile: "Software Engineer",
          address: "123 Developer Ave, Tech City",
          phone: "+1234567890",
          email: "john.doe@engineer.com",
          skill: "Java, React, Node.js, Microservices, Cloud Computing",
        },
        projectData: [
          {
            pTitle: "Cloud Migration Project",
            pDescription:
              "Led the migration of legacy systems to a cloud-based architecture, reducing operational costs by 25%.",
          },
        ],
        educationData: [
          {
            eTitle: "Bachelor of Science in Computer Science",
            eDescription: "Graduated with Honors from XYZ University.",
          },
        ],
        workData: [
          {
            wTitle: "Software Engineer at ABC Tech",
            wDescription:
              "Developed scalable applications and worked on microservices architecture.",
          },
        ],
        awardData: {
          awards: "Employee of the Year 2020, Innovator Award 2019",
        },
      };
      setThemeData(engineerResume);
    } else if (selectedResumeId === "resume3") {
      const architectResume = {
        personalData: {
          name: "Alice Johnson",
          summary:
            "An innovative architect with a passion for sustainable design and urban planning.",
          profile: "Architect",
          address: "789 Blueprint Blvd, Design City",
          phone: "+1234567891",
          email: "alice.johnson@architect.com",
          skill: "AutoCAD, SketchUp, Sustainable Design, Urban Planning",
        },
        projectData: [
          {
            pTitle: "Eco-friendly Housing Project",
            pDescription:
              "Designed energy-efficient homes that reduced energy consumption by 30%.",
          },
        ],
        educationData: [
          {
            eTitle: "Master of Architecture",
            eDescription: "Graduated with Distinction from ABC University.",
          },
        ],
        workData: [
          {
            wTitle: "Lead Architect at DEF Designs",
            wDescription:
              "Led a team of architects in designing commercial and residential buildings.",
          },
        ],
        awardData: {
          awards: "Sustainable Design Award 2021, Architect of the Year 2020",
        },
      };
      setThemeData(architectResume);
    } else if (selectedResumeId === "resume4") {
      const playerResume = {
        personalData: {
          name: "Michael King",
          summary:
            "A professional athlete with over 8 years of experience in competitive sports and team leadership.",
          profile: "Professional Football Player",
          address: "456 Sports Arena, Victory City",
          phone: "+9876543211",
          email: "michael.king@player.com",
          skill:
            "Team Leadership, Game Strategy, Physical Endurance, Collaboration",
        },
        projectData: [
          {
            pTitle: "National Championship Win",
            pDescription:
              "Played a key role in leading the team to victory in the 2023 National Football Championship.",
          },
        ],
        educationData: [
          {
            eTitle: "Bachelor of Science in Sports Management",
            eDescription: "Graduated with Honors from XYZ University.",
          },
        ],
        workData: [
          {
            wTitle: "Team Captain at GHI Football Club",
            wDescription:
              "Led the team to multiple victories and maintained high performance standards.",
          },
        ],
        awardData: {
          awards: "Best Player Award 2023, Sportsman of the Year 2022",
        },
      };
      setThemeData(playerResume);
    }
  };
  // single resume dummy data
  // const handleResumeChange = (e) => {
  //   const selectedResumeId = e.target.value;
  //   if (selectedResumeId !== resumeId) {
  //     setResumeId(selectedResumeId);
  //   }
  //   // If "Teaching" is selected, fill with predefined resume data
  //   if (selectedResumeId === "resume1") {
  //     const teachingResume = {
  //       personalData: {
  //         name: "Jane Smith",
  //         summary:
  //           "A passionate and dedicated educator with 10+ years of experience in primary education.",
  //         profile: "Primary School Teacher",
  //         address: "45 Learning St, Education City",
  //         phone: "+9876543210",
  //         email: "jane.smith@teacher.com",
  //         skill:
  //           "Classroom Management, Lesson Planning, Child Development, Communication",
  //       },
  //       projectData: [
  //         {
  //           pTitle: "Interactive Learning Program",
  //           pDescription:
  //             "Developed an interactive curriculum that improved student engagement by 40%.",
  //         },
  //       ],
  //       educationData: [
  //         {
  //           eTitle: "Master of Education",
  //           eDescription: "Graduated with Distinction from ABC University.",
  //         },
  //       ],
  //       workData: [
  //         {
  //           wTitle: "Lead Teacher at XYZ School",
  //           wDescription:
  //             "Led a team of teachers and coordinated the curriculum for grades 1-5.",
  //         },
  //       ],
  //       awardData: {
  //         awards: `Teacher of the Year 2022 Innovation in Education Award 2021`,
  //       },
  //     };

  //     setThemeData(teachingResume);
  //   }
  //   // For other resume types, you can implement similar logic later.
  // };
  console.log(themeData);

  if (loading) {
    return <ResumeSkeleton/>  }

  return (
    <>
      <Modal isOpen={show} onClose={setShow}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Save Resume</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              placeholder="Enter resume name"
              value={resumeName}
              onChange={(e) => setResumeName(e.target.value)}
            />
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={handleSave}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          alignItems: "center",
          gap: "20px",
          margin: "20px 0",
        }}
      >
        <div
          className="btn"
          style={{
            display: "flex",
          }}
        >
          <Button
            style={{ margin: "0 10px" }}
            colorScheme={"teal"}
            variant={"outline"}
            onClick={handlePrint}
          >
            <FaPrint size={24} />
          </Button>
          {/* <Button
          style={{ margin: "0 10px" }}
          colorScheme={"teal"}
          variant={"outline"}
          onClick={() => setShow(true)}
        >
          <FaSave size={24} />
        </Button> */}
        </div>
        <div className="themeSelect">
          <Select
            id="theme-select"
            colorScheme="teal"
            variant={"outline"}
            onChange={(e) => setCurrentTheme(e.target.value)}
            value={currentTheme}
          >
            <option value="" disabled>
              Select a theme
            </option>
            <option className=" " value="Theme1">
              Theme 1
            </option>
            <option className=" " value="Theme2">
              Theme 2
            </option>
            <option className=" " value="Theme3">
              Theme 3
            </option>
          </Select>
        </div>
        <div className="selectRole">
          {/* <Select
          id="theme-select"
          colorScheme="teal"
          variant={"outline"}
          onChange={handleResumeChange} // Update this to use the new handler
          value={resumeId}
          style={{
            
            outline: "none",
            cursor: "pointer",
          }}
        >
          <option value="" disabled>
            Select a Resume
          </option>
          <option value="resume1">Teaching</option>
          <option value="resume2">Engineer</option>
          <option value="resume3">Architect</option>
          <option value="resume4">Player</option>
          {resumes.map((resume, index) => (
            <option key={index} className=" " value={resume._id}>
              {resume.resumeName}
            </option>
          ))}
        </Select> */}
        </div>
      </div>

      <div id="main-box" className="d-flex justify-content-between  mt-4 mx-2">
        <UserDataCollect />
        <div
          id="theme-box-border"
          style={{
            // width: "40%",
          }}
        >
          {theme}
        </div>
      </div>
    </>
  );
};

export default BuilderArea;
