import React, { useContext, useEffect, useState } from "react";
import "./userCollectData.css";
import { IoMdCloudUpload } from "react-icons/io";
import {
  FormControl,
  Input,
  Heading,
  Textarea,
  Button,
  Switch,
} from "@chakra-ui/react";
import ResumeContext from "../../Context/ResumeContext";

const UserDataCollect = () => {
  const {
    themeData,
    checkAward,
    setCheckAward,
    checkProj,
    checkWork,
    setCheckProj,
    setCheckWork,
    setThemeData,
  } = useContext(ResumeContext);

  const [projectCount, setProjectCount] = useState(0);
  const [educationCount, setEducationCount] = useState(0);
  const [workCount, setWorkCount] = useState(0);
  const [selectedResume, setSelectedResume] = useState("");
  const [projectData, setProjectData] = useState([]);
  const [educationData, setEducationData] = useState([]);
  const [workData, setWorkData] = useState([]);
  const [personalData, setPersonalData] = useState({
    profileImage: "https://www.w3schools.com/howto/img_avatar.png",
    name: "Your Name",
    summary: "Your summary",
    profile: "Work Profile",
    address: "Address Line",
    phone: "Phone Number",
    email: "Email Address",
    skill: "Your, Skills, are, shown, here",
  });
  const [awardData, setAwardData] = useState({
    awards: "Your Awards are shown here",
  });

  useEffect(() => {
    setPersonalData(themeData.personalData || {});
    setProjectData(themeData.projectData || []);
    setEducationData(themeData.educationData || []);
    setWorkData(themeData.workData || []);
    setAwardData(themeData.awardData || {});
  }, [themeData]);

  // To Add Personal Data to the State
  const handleChangePersonal = (e) => {
    const { name, value } = e.target;

    if (name === "profileImage") {
      // Check if a file is selected
      const file = e.target.files[0];
      if (file) {
        // Create a URL for the selected file
        const imageUrl = URL.createObjectURL(file);
        setPersonalData((prev) => ({
          ...prev,
          profileImage: imageUrl,
        }));

        // Update theme data as well
        setThemeData((prevData) => ({
          ...prevData,
          personalData: { ...personalData, profileImage: imageUrl },
        }));
      }
    } else {
      const updatedPersonalData = { ...personalData, [name]: value };
      setPersonalData(updatedPersonalData);
      setThemeData((prevData) => ({
        ...prevData,
        personalData: updatedPersonalData,
      }));
    }
  };

  // To Add Project Data to the State
  const handleChangeProject = (e, index) => {
    const { name, value } = e.target;
    const updatedProjects = [...projectData];
    updatedProjects[index] = {
      ...updatedProjects[index],
      [name]: value,
    };
    setProjectData(updatedProjects);

    setThemeData((prevData) => ({
      ...prevData,
      projectData: updatedProjects,
    }));
  };

  const handleProjectClick = (e) => {
    e.preventDefault();
    const newProject = {
      pTitle: "Project Title",
      pDescription: "Project Description",
    };
    setProjectData([...projectData, newProject]);
    setThemeData((prevData) => ({
      ...prevData,
      projectData: [...projectData, newProject],
    }));
    setProjectCount(projectCount + 1);
  };

  // To Add Education Data to the State
  const handleChangeEducation = (e, index) => {
    const { name, value } = e.target;
    const updatedEducation = [...educationData];
    updatedEducation[index] = {
      ...updatedEducation[index],
      [name]: value,
    };
    setEducationData(updatedEducation);

    setThemeData((prevData) => ({
      ...prevData,
      educationData: updatedEducation,
    }));
  };

  const handleEducationClick = (e) => {
    e.preventDefault();
    const newEducation = {
      eTitle: "Education Title",
      eDescription: "Education Description",
    };
    setEducationData([...educationData, newEducation]);
    setThemeData((prevData) => ({
      ...prevData,
      educationData: [...educationData, newEducation],
    }));
    setEducationCount(educationCount + 1);
  };

  // To Add Work Data to the State
  const handleChangeWork = (e, index) => {
    const { name, value } = e.target;
    const updatedWork = [...workData];
    updatedWork[index] = {
      ...updatedWork[index],
      [name]: value,
    };
    setWorkData(updatedWork);

    setThemeData((prevData) => ({
      ...prevData,
      workData: updatedWork,
    }));
  };

  const handleWorkClick = (e) => {
    e.preventDefault();
    const newWork = { wTitle: "Work Title", wDescription: "Work Description" };
    setWorkData([...workData, newWork]);
    setThemeData((prevData) => ({
      ...prevData,
      workData: [...workData, newWork],
    }));
    setWorkCount(workCount + 1);
  };

  // To Add Award & Achievement Data to the State
  const handleChangeAwards = (e) => {
    const { name, value } = e.target;
    setAwardData({ ...awardData, [name]: value });
    setThemeData((prevData) => ({
      ...prevData,
      awardData: { ...awardData, [name]: value },
    }));
  };

  console.log(themeData);
  return (
    <div id="form-collect">
      {/* Personal Details Area */}
      <div id="form-personal" className="mb-2">
        <Heading as="h4" size="md" className="mb-2">
          Personal Details
        </Heading>
        <hr />
        <FormControl isRequired className="my-2">
          <div className="file">
            <label htmlFor="input-file">
              <IoMdCloudUpload size={30} />
              Select a file
            </label>
            <input
              accept="image/*"
              name="profileImage"
              onChange={handleChangePersonal}
              id="input-file"
              type="file"
            />
            <img
              className="blah"
              src={personalData.profileImage}
              alt="your profile preview"
            />
          </div>
        </FormControl>
        <FormControl isRequired className="my-2">
          <Input
            name="name"
            onChange={handleChangePersonal}
            type="text"
            placeholder="Your Name"
            value={personalData.name}
          />
        </FormControl>
        <FormControl isRequired className="my-2">
          <Input
            name="summary"
            onChange={handleChangePersonal}
            type="text"
            placeholder="Your Summary"
            value={personalData?.summary}
          />
        </FormControl>
        <FormControl isRequired className="my-2">
          <Input
            name="profile"
            onChange={handleChangePersonal}
            type="text"
            placeholder="Work Profile"
            value={personalData.profile}
          />
        </FormControl>
        <FormControl isRequired className="my-2">
          <Input
            name="address"
            onChange={handleChangePersonal}
            type="text"
            placeholder="Address"
            value={personalData.address}
          />
        </FormControl>
        <FormControl isRequired className="my-2">
          <Input
            name="phone"
            onChange={handleChangePersonal}
            type="tel"
            placeholder="Phone number"
            value={personalData.phone}
          />
        </FormControl>
        <FormControl isRequired className="my-2">
          <Input
            name="email"
            onChange={handleChangePersonal}
            type="email"
            placeholder="Email id"
            value={personalData.email}
          />
        </FormControl>
      </div>

      {/* Skills Area */}
      <div id="form-personal" className="mb-2">
        <Heading as="h4" size="md" className="my-2">
          Technical Skills
        </Heading>
        <hr />
        <FormControl isRequired className="my-2">
          <Input
            name="skill"
            onChange={handleChangePersonal}
            type="text"
            placeholder="Separate skills by comma"
            value={personalData.skill}
          />
        </FormControl>
      </div>

      {/* Education Area */}
      <div id="form-personal" className="mb-2">
        <Heading as="h4" size="md" className="my-2">
          Education
        </Heading>
        <hr />
        <Button
          onClick={handleEducationClick}
          className="my-3 w-100"
          colorScheme="teal"
          variant="solid"
        >
          Add Education
        </Button>
        {educationData.map((_, index) => (
          <div key={index}>
            <Input
              name="eTitle"
              onChange={(e) => handleChangeEducation(e, index)}
              type="text"
              placeholder="Education Title"
              value={educationData[index]?.eTitle || ""}
            />
            <Textarea
              name="eDescription"
              onChange={(e) => handleChangeEducation(e, index)}
              placeholder="Education Description"
              value={educationData[index]?.eDescription || ""}
            />
          </div>
        ))}
      </div>

      {/* Project Area */}
      <div id="form-personal" className="mb-2">
        <div className="d-flex align-items-center justify-content-between">
          <Heading as="h4" size="md" className="my-2">
            Projects
          </Heading>
          <Switch
            defaultChecked={true}
            onChange={() => setCheckProj(!checkProj)}
            colorScheme="teal"
          />
        </div>
        <hr />
        <Button
          onClick={handleProjectClick}
          className="my-3 w-100"
          colorScheme="teal"
          variant="solid"
        >
          Add Project
        </Button>
        {projectData.map((_, index) => (
          <div key={index}>
            <Input
              name="pTitle"
              onChange={(e) => handleChangeProject(e, index)}
              type="text"
              placeholder="Project Title"
              value={projectData[index]?.pTitle || ""}
            />
            <Textarea
              name="pDescription"
              onChange={(e) => handleChangeProject(e, index)}
              placeholder="Project Description"
              value={projectData[index]?.pDescription || ""}
            />
          </div>
        ))}
      </div>

      {/* Work Area */}
      <div id="form-personal" className="mb-2">
        <div className="d-flex align-items-center justify-content-between">
          <Heading as="h4" size="md" className="my-2">
            Work Experience
          </Heading>
          <Switch
            defaultChecked={true}
            onChange={() => setCheckWork(!checkWork)}
            colorScheme="teal"
          />
        </div>
        <hr />
        <Button
          onClick={handleWorkClick}
          className="my-3 w-100"
          colorScheme="teal"
          variant="solid"
        >
          Add Work Experience
        </Button>
        {workData.map((_, index) => (
          <div key={index}>
            <Input
              name="wTitle"
              onChange={(e) => handleChangeWork(e, index)}
              type="text"
              placeholder="Work Title"
              value={workData[index]?.wTitle || ""}
            />
            <Textarea
              name="wDescription"
              onChange={(e) => handleChangeWork(e, index)}
              placeholder="Work Description"
              value={workData[index]?.wDescription || ""}
            />
          </div>
        ))}
      </div>

      {/* Awards Area */}
      <div id="form-personal" className="mb-2">
        <div className="d-flex align-items-center justify-content-between">
          <Heading as="h4" size="md" className="my-2">
            Awards & Achievements
          </Heading>
          <Switch
            defaultChecked={true}
            onChange={() => setCheckAward(!checkAward)}
            colorScheme="teal"
          />
        </div>
        <hr />
        <FormControl isRequired className="my-2">
          <Textarea
            name="awards"
            onChange={handleChangeAwards}
            placeholder="Awards"
            value={awardData.awards}
          />
        </FormControl>
      </div>
    </div>
  );
};

export default UserDataCollect;
