import React, { useContext } from "react";
import {
  Box,
  Text,
  Image,
  Heading,
  Badge,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { useMediaQuery } from "react-responsive";
import { BsEye } from "react-icons/bs";
import ResumeContext from "../../Context/ResumeContext";
import "./theme2.css";

const Theme2 = (props) => {
  const { componentRef, themeData } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const { name, address, phone, email, profile, profileImage, summary, skill } =
    themeData.personalData;

  const { checkProj, checkWork, checkAward } = useContext(ResumeContext);
  const projectData = themeData.projectData || [];
  const educationData = themeData.educationData || [];
  const workData = themeData.workData || [];
  const awards = themeData.awardData?.awards || "";

  const ResumeContent = () => (
    <Box id="section-to-print" ref={componentRef}>
      <Box id="theme2">
        <header
          id="info"
          className={`text-center m-2 d-flex ${
            isMobile ? "flex-column align-items-center" : "justify-content-between align-items-center"
          }`}
        >
          <Box className="info-text text-start">
            <Heading as="h2" size="xl" className="mb-2">
              {name}
            </Heading>
            <Text fontWeight={"500"} fontSize="lg" className="mt-1 mb-2">
              {profile}
            </Text>
            <Text
              fontSize="sm"
              className={`mt-1 mb-2 summary-text ${
                isMobile ? "text-center" : ""
              }`}
            >
              {summary}
            </Text>
          </Box>
          <Box className="mt-2">
            <Image
              id="resume-avatar"
              borderRadius={100}
              boxSize={isMobile ? "100px" : "150px"}
              src={profileImage}
              alt="Profile Picture"
            />
          </Box>
        </header>
        <div className="w-100 border border-dark m-auto"></div>
        <section className={`bottom-part ${isMobile ? "mt-3" : "d-flex mt-3"}`}>
          <section className="partition-1">
            <Box>
              <Heading fontSize="2xl" className="my-2">
                Contact
              </Heading>
              <Box className="mt-3">
                <Heading fontSize="md" className="my-2">
                  Phone
                </Heading>
                <Text fontSize={"sm"}>{phone}</Text>
                <Heading fontSize="md" className="my-2">
                  Email
                </Heading>
                <Text fontSize={"sm"}>{email}</Text>
                <Heading fontSize="md" className="my-2">
                  Address
                </Heading>
                <Text width={"190px"} fontSize={"sm"}>
                  {address}
                </Text>
              </Box>
            </Box>
            <Box className="mt-5">
              <Heading fontSize="2xl" className="my-2">
                Skills
              </Heading>
              <Box className="mt-3">
                {skill?.split(",").map((item, index) => (
                  <div key={index}>
                    <Badge className="mx-1" key={index}>
                      {item}
                    </Badge>
                    <br />
                  </div>
                ))}
              </Box>
            </Box>
          </section>

          {!isMobile && (
            <div style={{ height: "536px" }} className="border border-dark mx-4"></div>
          )}

          <section className="partition-2">
            <Box id="education-area">
              <Heading fontSize="2xl" className="my-2">
                Education
              </Heading>
              {educationData?.map((education, index) => (
                <Box key={index} className="mt-3">
                  <Heading fontSize="md" className="my-2">
                    {education.eTitle}
                  </Heading>
                  <Box className="sub-details">
                    {education.eDescription.split(",").map((desc, i) => (
                      <li key={i}>{desc}</li>
                    ))}
                  </Box>
                </Box>
              ))}
            </Box>

            {!checkProj && (
              <Box id="project-area">
                <Heading fontSize="2xl" className="mt-4">
                  Projects
                </Heading>
                {projectData.map((project, index) => (
                  <Box key={index} className="mt-1">
                    <Heading fontSize="md" className="my-2">
                      {project.pTitle}
                    </Heading>
                    <Box className="sub-details">
                      {project.pDescription.split(",").map((desc, i) => (
                        <li key={i}>{desc}</li>
                      ))}
                    </Box>
                  </Box>
                ))}
              </Box>
            )}
            {!checkWork && (
              <Box id="experience-area">
                <Heading fontSize="2xl" className="mt-4">
                  Work Experience
                </Heading>
                {workData.map((work, index) => (
                  <Box key={index} className="mt-1">
                    <Heading fontSize="md" className="my-2">
                      {work.wTitle}
                    </Heading>
                    <Box className="sub-details">
                      {work.wDescription.split(",").map((desc, i) => (
                        <li key={i}>{desc}</li>
                      ))}
                    </Box>
                  </Box>
                ))}
              </Box>
            )}
            {!checkAward && (
              <Box id="award-area">
                <Heading fontSize="2xl" className="mt-4">
                  Awards & Achievement
                </Heading>
                <Box className="mt-1">
                  {awards.split(",").map((element, index) => (
                    <li key={index}>{element}</li>
                  ))}
                </Box>
              </Box>
            )}
          </section>
        </section>
      </Box>
    </Box>
  );

  return (
    <>
      {isMobile ? (
        <>
          <Box position="fixed" bottom={4} right={4} zIndex={10}>
            <IconButton
              aria-label="Show Resume Preview"
              icon={<BsEye />}
              onClick={onOpen}
              colorScheme="blue"
              rounded="full"
              size="lg"
            />
          </Box>
          <Modal isOpen={isOpen} onClose={onClose} size="full">
            <ModalOverlay />
            <ModalContent>
              <ModalCloseButton />
              <ModalBody>
                <ResumeContent />
              </ModalBody>
            </ModalContent>
          </Modal>
        </>
      ) : (
        <ResumeContent />
      )}
    </>
  );
};

export default Theme2;
