'use client'

import React, { useContext } from "react"
import { useMediaQuery } from "react-responsive"
import {
  Heading,
  Text,
  Box,
  Badge,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure
} from "@chakra-ui/react"
import { ImLocation } from "react-icons/im"
import { GrMail } from "react-icons/gr"
import { BsFillTelephoneFill, BsEye } from "react-icons/bs"
import ResumeContext from "../../Context/ResumeContext"

export default function Theme1({ themeData, componentRef }) {
  const { checkProj, checkWork, checkAward } = useContext(ResumeContext)
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { name, profile, address, phone, email, skill, summary } = themeData.personalData
  const projectData = themeData.projectData || []
  const educationData = themeData.educationData || []
  const workData = themeData.workData || []
  const awards = themeData.awardData?.awards || ""

  const ResumeContent = () => (
    <Box id="section-to-print" ref={componentRef}>
      <Box
        id="theme1"
        _dark={{ border: "1px solid white" }}
        padding={{ base: 4, md: 8 }}
        maxWidth="1200px"
        margin="0 auto"
      >
        {/* Personal Info */}
        <header
          id="info"
          textAlign={{ base: "center", md: "left" }}
          className="mt-2"
        >
          <Heading
            as="h2"
            size={{ base: "lg", md: "2xl" }}
            className="mb-2"
          >
            {name}
          </Heading>
          <Text fontSize={{ base: "sm", md: "md" }} className="text-muted my-1">
            <Box display="flex" flexWrap="wrap" justifyContent="center">
              <span className="mx-2">
                <ImLocation className="d-inline mx-1" />
                {address}
              </span>
              |
              <span className="mx-2">
                <GrMail className="d-inline mx-1" />
                {email}
              </span>
              |
              <span className="mx-2">
                <BsFillTelephoneFill className="d-inline mx-1" />
                {phone}
              </span>
            </Box>
          </Text>
          <Heading as="h3" size={{ base: "sm", md: "md" }} className="mt-1 mb-2">
            {profile}
          </Heading>
        </header>

        {/* Skills Section */}
        <section id="skills" className="my-2">
          <Heading
            bg={"#D2E4E1"}
            as="h3"
            size="md"
            px={{ base: 8, md: 20 }}
            py={2}
          >
            TECHNICAL SKILLS
          </Heading>
          <Box
            id="skills-set"
            className="basic-set"
            display="flex"
            flexWrap="wrap"
            justifyContent={{ base: "center", md: "flex-start" }}
            mt={4}
          >
            {skill.split(",").map((element, index) => (
              <Badge key={index} className="skill-badge" variant="solid" m={1}>
                {element}
              </Badge>
            ))}
          </Box>
        </section>

        {/* Project Section */}
        {!checkProj && (
          <section id="projects" className="my-2">
            <Heading
              bg={"#D2E4E1"}
              as="h3"
              size="md"
              px={{ base: 8, md: 20 }}
              py={2}
            >
              PROJECTS
            </Heading>
            <Box id="project-set" className="basic-set mt-4">
              {projectData.map((project, index) => (
                <Box key={index} mb={4}>
                  <Text fontWeight="bold">{project.pTitle}</Text>
                  <Box className="sub-details" pl={4}>
                    {project.pDescription.split(",").map((desc, i) => (
                      <li key={i}>{desc}</li>
                    ))}
                  </Box>
                </Box>
              ))}
            </Box>
          </section>
        )}

        {/* Education Section */}
        <section id="education" className="my-2">
          <Heading
            bg={"#D2E4E1"}
            as="h3"
            size="md"
            px={{ base: 8, md: 20 }}
            py={2}
          >
            EDUCATION
          </Heading>
          <Box id="education-set" className="basic-set mt-4">
            {educationData.map((education, index) => (
              <Box key={index} mb={4}>
                <Text fontWeight="bold">{education.eTitle}</Text>
                <Box className="sub-details" pl={4}>
                  {education.eDescription.split(",").map((desc, i) => (
                    <li key={i}>{desc}</li>
                  ))}
                </Box>
              </Box>
            ))}
          </Box>
        </section>

        {/* Work Experience Section */}
        {!checkWork && (
          <section id="experience" className="my-2">
            <Heading
              bg={"#D2E4E1"}
              as="h3"
              size="md"
              px={{ base: 8, md: 20 }}
              py={2}
            >
              WORK EXPERIENCE
            </Heading>
            <Box id="experience-set" className="basic-set mt-4">
              {workData.map((work, index) => (
                <Box key={index} mb={4}>
                  <Text fontWeight="bold">{work.wTitle}</Text>
                  <Box className="sub-details" pl={4}>
                    {work.wDescription.split(",").map((desc, i) => (
                      <li key={i}>{desc}</li>
                    ))}
                  </Box>
                </Box>
              ))}
            </Box>
          </section>
        )}

        {/* Awards Section */}
        {!checkAward && (
          <section id="awards" className="my-2">
            <Heading
              bg={"#D2E4E1"}
              as="h3"
              size="md"
              px={{ base: 8, md: 20 }}
              py={2}
            >
              AWARDS & ACHIEVEMENTS
            </Heading>
            <Box id="award-set" className="basic-set mt-4">
              <ul>
                {awards.split(",").map((element, index) => (
                  <li key={index}>{element}</li>
                ))}
              </ul>
            </Box>
          </section>
        )}
      </Box>
    </Box>
  )

  return (
    <>
      {isMobile ? (
        <Box position="fixed" bottom={4} right={4} zIndex={10}>
          <IconButton
            aria-label="Show Resume Preview"
            icon={<BsEye />}
            onClick={onOpen}
            colorScheme="blue"
            rounded="full"
            size="lg"
          />
        </Box>
      ) : null}
      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent maxWidth="100%" height="100vh" overflowY="auto">
          <ModalCloseButton />
          <ModalBody>
            <ResumeContent />
          </ModalBody>
        </ModalContent>
      </Modal>
      {!isMobile && <ResumeContent />}
    </>
  )
}
